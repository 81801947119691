<template>
  <div class="view pa24">
    <el-form ref="ruleForm" label-position="top" class="demo-ruleForm">
      <el-form-item>
        <div class="flex-a-c ">
          <p class="fs9 fwbold mr10">强制登录授权开关</p>
          <el-switch v-model="infoSwitch" @change="switch_tap(infoSwitch)">
          </el-switch>
        </div>
        <div>
          开启后，未登录用户需登录后才可操作查看页面内容，拒绝登录则任何操作都会触发登录授权弹窗。<br />关闭状态，未登录用户只可查看名片页相关内容，查看其他页面也需登录授权才可查看。
        </div>
      </el-form-item>
      <el-form-item>
        <div class="flex-a-c ">
          <p class="fs9 fwbold mr10">授权手机号开关</p>
          <el-switch v-model="phoneSwitch" @change="switch_tap(phoneSwitch)">
          </el-switch>
        </div>
        <div>
          开启后，未登录用户登录授权后并授权手机号后才可操作查看页面内容，拒绝授权则任何操作都会触发手机号授权弹窗。<br />关闭状态，未登录用户登录授权后即可操作查看页面内容。
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { switchCompanyModel, querySwitchCompanyModel } from '@/api/wechat'
import { fetchCompany } from '@/api/companyManage.js'

export default {
  data() {
    return {
      infoSwitch: false,
      phoneSwitch: false,
      companyId: ''
    };
  },
  created() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      fetchCompany()
        .then(res => {
          console.log(res)
          this.companyId = res.data.companyId
          let data = {
            companyId: this.companyId
          }
          querySwitchCompanyModel(data)
            .then(res => {
              if (res.data) {
                this.infoSwitch = res.data.infoSwitch
                this.phoneSwitch = res.data.phoneSwitch
              }
            })
        })
    },
    switch_tap() {
      let data = {
        infoSwitch: this.infoSwitch,
        phoneSwitch: this.phoneSwitch,
        companyId: this.companyId
      }
      switchCompanyModel(data)
        .then(res => {
          if (res.code == 200) {
            this.$message.success('操作成功')
          } else {
            this.$message.error('操作失败')
          }
        })
    }
  }
};
</script>
