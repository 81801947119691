<template>
  <div class="view pa24">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" class="demo-ruleForm">
      <el-form-item label="微信支付商户号（mchid）" prop="merchantId">
        <el-col>
          <el-input :placeholder="isSucceed('merchantId')" v-model="ruleForm.merchantId"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="APIv2密钥" prop="key">
        <el-col>
          <el-input :placeholder="isSucceed('key')" v-model="ruleForm.key"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="API证书（只能是p12证书，从微信支付商户后台生成并获取）" prop="certData">
        <el-col :lg="12" class="mb20">
          <el-upload class="upload-demo" action="" :before-upload="beforeUpload" :on-remove="onRemove"
            :on-exceed="onExceed" :limit="1">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-col>
        <el-col :lg="24">
          <el-button type="primary" @click="submitPayInfo">提交信息</el-button>
          <el-button type="danger" @click="resetAuthorization">删除信息</el-button>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { submitPayInfo } from '@/api/wechat'

export default {
  props: {
    accountMap: {
      default: {}
    }
  },
  data() {
    return {
      ruleForm: {
        merchantId: '',
        key: '',
        certData: '',
      },
      rules: {
        merchantId: [
          { required: true, message: "请输入微信支付商户号", trigger: "blur" },
        ],
        key: [
          { required: true, message: "请输入APIv2密钥", trigger: "blur" },
        ],
        certData: [
          { required: true, message: "请上传API证书", trigger: "blur" },
        ],
      }
    };
  },
  created() {
    console.log("accountMap", this.accountMap)
  },
  methods: {
    beforeUpload(file) {
      const that = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        let base64Data = e.target.result;
        base64Data = base64Data.substring(base64Data.indexOf(',') + 1);
        that.ruleForm.certData = base64Data;
      };
      return false;
    },
    onRemove() {
      this.ruleForm.certData = '';
    },
    onExceed() {
      this.$message.warning('只能上传一个证书文件')
    },
    isSucceed(type) {
      let str = this.rules[type][0].message
      if (this.accountMap.state == 1) {
        str = type == 'merchantId' ? this.accountMap.merchantId : str
      }
      return str
    },
    submitPayInfo() {
      if (!this.ruleForm.merchantId || !this.ruleForm.key || !this.ruleForm.certData) {
        return this.$message.error('请检查资料是否完整')
      }
      let data = {
        merchantId: this.ruleForm.merchantId,
        key: this.ruleForm.key,
        certData: this.ruleForm.certData,
        accountType: 4 //用户支付
      }
      submitPayInfo(data)
        .then(res => {
          console.log(res);
          this.$message.success('提交成功');
        })
        .catch(err => {
          console.log(err);
          this.$message.error('提交失败');
        })
    },
    resetAuthorization() {
      let accountId = this.accountMap.accountId
      if (!accountId) {
        this.$message.error('还未提交微信支付商户信息')
        return
      }
      this.$emit('resetAuthorization', accountId)
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-upload--text {
  background-color: #fff;
  border: 0px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 84px;
  height: 34px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-input {
  width: 500px;
}
</style>
