<template>
  <div v-if="accountMap">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="用户端" name="first">
        <userTerminal :accountMap="accountMap[1] || {}" @getAppListInfo="getAppListInfo"
          @resetAuthorization="resetAuthorization" v-if="activeName == 'first'" />
      </el-tab-pane>
      <el-tab-pane label="crm端" name="second">
        <crm :accountMap="accountMap[2] || {}" @getAppListInfo="getAppListInfo" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'second'" />
      </el-tab-pane>
      <el-tab-pane label="用户端支付" name="third">
        <userpay :accountMap="accountMap[4] || {}" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'third'" />
      </el-tab-pane>
      <el-tab-pane label="crm支付" name="fourthly" v-if="userInfo.main && userInfo.roleId">
        <crmpay :accountMap="accountMap[6] || {}" @resetAuthorization="resetAuthorization" v-if="activeName == 'fourthly'" />
      </el-tab-pane>
      <el-tab-pane label="公众号设置" name="offiaccount" v-if="userInfo.main && userInfo.roleId">
        <offiaccount :accountMap="accountMap[5] || {}" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'offiaccount'" />
      </el-tab-pane>
      <el-tab-pane label="登录设置" name="fifth" v-if="userInfo.main">
        <authorizationSwitch v-if="activeName == 'fifth'" />
      </el-tab-pane>
      <el-tab-pane v-if="qywxSwitch" label="企业微信" name="qywx">
        <qywx :accountMap="accountMap[8] || []" @getAppListInfo="getAppListInfo" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'qywx'" />
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="是否重置授权信息" width="30%" :center="true" :modal-append-to-body="false" :visible="dialogTableVisible"
      @close="dialogClose">
      <p class="fs8 text-align cA1">
        重置以后需要重新配置小程序信息
      </p>
      <div class="mt40 flex-c-c ">
        <el-button type="primary" plain @click="cancel">
          取消
        </el-button>
        <el-button type="primary" @click="onConfirmShop">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userTerminal from './components/userTerminal';
import crm from './components/crm';
import userpay from './components/userpay';
import crmpay from './components/crmpay';
import authorizationSwitch from './components/authorizationSwitch';
import qywx from './components/qywx';
import offiaccount from './components/offiaccount';
import { qywxSetting } from "@/api/qywx.js";
import { getCompanyAccountList, unbindCompanyAccount } from "@/api/authorization"

export default {
  data() {
    return {
      activeName: "first",
      accountMap: [],
      userInfo: '',
      dialogTableVisible: false,
      accountId: '',
      qywxSwitch: 0,
      isQywxAuth: 0
    };
  },
  components: {
    userTerminal,
    crm,
    userpay,
    crmpay,
    authorizationSwitch,
    qywx,
    offiaccount
  },
  created() {
    this.userInfo = this.$store.state.loginRoot.userInfo
    this.getAppListInfo()
    // this.qywxSetting()
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName
    }
    if (this.$route.query.auth_code) {
      if (this.$route.query.state) {
        this.activeName = 'qywx'
      } else {
        this.activeName = localStorage.getItem('crmAppId') ? 'second' : 'first'
      }
    }
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
    async getAppListInfo() {
      const result = await getCompanyAccountList({ appid: true })
      console.log('getAppListInfo', result)
      if (result && result.data) {
        let tmp = [];
        result.data.map(val => {
          tmp[val.accountType] = val;
        });
        this.accountMap = tmp;
        console.log('getAppListInfo', this.accountMap)
      }
    },
    dialogClose() {
      this.dialogTableVisible = false
    },
    resetAuthorization(id) {
      this.accountId = id
      this.dialogTableVisible = true
    },
    cancel() {
      this.dialogTableVisible = false
    },
    async onConfirmShop() {
      let data = {
        accountId: this.accountId
      }
      const result = await unbindCompanyAccount(data)
      console.log(result)
      if (result.code == 200) {
        this.$message.success('重置成功！')
        this.dialogTableVisible = false
        this.getAppListInfo()
      } else {
        this.$message.error('重置失败，请稍后重试')
      }
    },
    qywxSetting() {
      qywxSetting({ ignore: true })
        .then(res => {
          console.log(res)
          if (res.success && res.code == 200 && res.data) {
            this.qywxSwitch = res.data.qywxSwitch
            this.isQywxAuth = res.data.isQywxAuth
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
};
</script>

<style lang="scss" scoped></style>