<template>
  <div class="view pa24">
    <p class="cA2 mb10">
      <b>企业微信授权流程</b><br />
      ① 在此填写相关企业微信配置信息。<br />
      ② 授权成功后，点击“扫码授权”，使用授权的企业微信进行扫码安装第三方应用(扫码授权)。<br />
      ③ 安装授权后，在“企业管理”--“企业成员”--“部门设置”点击“导入企微组织机构”即可导入企业微信组织机构信息（未在系统内创建名片的企业微信成员账号不可导入进名片系统内）。
    </p>
    <div v-if="accountId">
      <table class="align-cen-table textc table table-hover table-bordered bgf6f9fa fixed-table text-ellipsis">
        <thead>
        <tr>
          <th>配置名称</th>
          <th>企业ID</th>
          <th width="400">应用Secret</th>
          <th>授权状态</th>
          <th>创建时间</th>
        </tr>
        </thead>
        <tbody class="bgfff">
        <tr>
          <td>{{ruleForm.appName}}</td>
          <td>{{ruleForm.appId}}</td>
          <td>{{ruleForm.appSecret}}</td>
          <td>{{isQywxAuth ? '已授权' : '未授权'}}</td>
          <td>{{ruleForm.createTime | getDataTimeSec('yyyy-MM-dd hh:mm:ss')}}</td>
        </tr>
        </tbody>
        
      </table>
      <el-button type="primary" v-if="userInfo.roleId && qywxSwitch" @click="toWeChat"><span v-if="isQywxAuth">重新</span>授权</el-button>
      <el-button type="primary" @click="resetQywx">重置配置</el-button>
    </div>
    <el-form
      v-else
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-position="top"
      class="demo-ruleForm"
    >
      <el-form-item label="配置名称" prop="appName">
        <el-col :lg="11">
          <el-input
            placeholder="请输入配置名称"
            v-model="ruleForm.appName"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="企业ID" prop="appId">
        <el-col :lg="11">
          <el-input
            type="text"
            placeholder="请输入企业ID"
            v-model="ruleForm.appId"
          ></el-input>
          <p>
            登录企业微信（
            <a style="color:#20aee3" href="https://work.weixin.qq.com/">work.weixin.qq.com</a>
            ）进入【<a style="color:#20aee3" href="https://work.weixin.qq.com/wework_admin/frame#/profile">我的企业</a>】--【企业信息中的最下面的企业ID，以ww开头18位字符】）
          </p>
        </el-col>
      </el-form-item>
      <el-form-item label="自建应用Secret" prop="appSecret">
        <el-col :lg="11">
          <el-input
            type="text"
            placeholder="请输入自建应用Secret"
            v-model="ruleForm.appSecret"
            maxlength="50"
          ></el-input>
          <p>
            登录企业微信（
            <a style="color:#20aee3" href="https://work.weixin.qq.com">work.weixin.qq.com</a>
            ）进入【<a style="color:#20aee3" href="https://work.weixin.qq.com/wework_admin/frame#/apps">应用管理</a>】--【自建】--【创建应用】--【进入创建的应用】--【Secret】--【查看】
          </p>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :lg="11">
          <el-button type="primary" @click="submitQywx">保存</el-button>
        </el-col>
      </el-form-item>
    </el-form>
    <el-dialog title="是否重置企业微信信息" 
               width="30%"
               :center="true"
               :modal-append-to-body="false"
               :visible="dialogTableVisible"
               @close="dialogClose">
        <p class="fs8 text-align cA1">
          重置以后需要重新配置企业微信信息
        </p>
        <div class="mt40 flex-c-c ">
          <el-button type="primary" plain @click="dialogClose">
            取消
          </el-button>
          <el-button type="primary" @click="onConfirmReset">
            确认
          </el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {getCompanyAccountList, componentloginpage, unbindCompanyAccount} from "@/api/authorization"
import { Message } from 'element-ui'
import { getDataTimeSec } from "@/utils";
import { authUrl, callback, qywxSetting } from "@/api/qywx.js";
export default {
  props:{
    accountMap: {
      default: ''
    }
  },
  data() {
    return {
      accountId: 0,
      dialogTableVisible: false,
      ruleForm: {},
      ruleForm_: {
        appName: "企业微信",
        appId: "",
        appSecret: "",
      },
      rules: {
        appName: [
          { required: true, message: "请输入配置名称", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "请输入企业ID", trigger: "blur" },
        ],
        appSecret: [
          { required: true, message: "请输入自建应用Secret", trigger: "blur" },
        ],
      },
      idMap:{
        1: 'userApp',
        2: 'crmApp',
        3: 'pcData',
        4: 'payInfo',
        8: 'qywx',
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      authUrlImg: "",
      qywxSwitch: 0,
      isQywxAuth: 0,
      authCode: ''
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created(){
    this.accountId = this.accountMap.accountId
    this.ruleForm = this.accountMap
    if(!this.accountId) {
      this.ruleForm = this.ruleForm_
    }
    console.log('ruleForm:', this.ruleForm)
    this.authCode = this.$route.query.auth_code
    if(this.authCode) {
      this.WeChatCallback()
    }
    this.qywxSetting()
  },
  computed:{
    checkAppSecret(){
     return  this.ruleForm.AppSecret ? '' : 'password'
    }
  },
  methods:{
    async submitQywx(){
      if(!this.ruleForm.appName){
        return this.$message.error('请输入配置名称')
      }
      if(!this.ruleForm.appId){
        return this.$message.error('请输入企业ID')
      }
      if(!this.ruleForm.appSecret){
        return this.$message.error('请输入自建应用Secret')
      }
      let data = {
        appName: this.ruleForm.appName,
        appId: this.ruleForm.appId,
        appSecret: this.ruleForm.appSecret,
        accountType: 8
      }
      const result =  await componentloginpage(data)
      console.log(result)
      if(result.code == 200){
        Message({message:"提交成功",type:'success'})
        this.getAppInfo()
      }else{
        Message({message:"提交失败",type:'error'})
      }
    },
    async getAppInfo(){
      const result =  await getCompanyAccountList({appid:true})
      console.log(result)
      if(result.data){
        let tmp = {};
        result && result.data.map(val => {
          tmp[val.accountType] = val;
          if(val.accountType == 8){
              this.accountId = val.accountId
              this.ruleForm = val
          }
        });
        this.accountMap = tmp;
        console.log(this.accountMap) 
      }
    },
    dialogClose(){
      this.dialogTableVisible = false
    },
    resetQywx(){
      this.dialogTableVisible = true
    },
    async onConfirmReset(){
      let data = {
        accountId:this.accountId
      }
      const result =  await unbindCompanyAccount(data)
      console.log(result)
      if(result.code == 200){
        this.$message.success('重置成功！')
        this.dialogTableVisible = false
        this.accountId = 0
        this.ruleForm = this.ruleForm_
      }else{
        this.$message.error('重置失败，请稍后重试')
      }
    },
    authUrl(){
      authUrl({ignore:true})
      .then(res=>{
        console.log(res)
        this.authUrlImg = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    },
    qywxSetting(){
      qywxSetting({ignore:true})
      .then(res=>{
        console.log(res)
        if (res.success && res.code == 200 && res.data) {
          this.qywxSwitch = res.data.qywxSwitch
          this.isQywxAuth = res.data.isQywxAuth
          // this.qywxSwitch && !this.isQywxAuth && this.authUrl()
        }
      })
      .catch(err=>{
        console.log(err)
      })
    },
    WeChatCallback(){
      var that = this
      let data = {
        auth_code:this.$route.query.auth_code,
        state:this.$route.query.state,
        expires_in:this.$route.query.expires_in
      }
      console.log('WeChatCallback', data)
      if(!data.auth_code || !data.state || !data.expires_in) {
        return
      }
      callback(data)
      .then(res=>{
        console.log(res)
        if (res.code == 200) {
          this.$message.success({
            message: '授权成功',
            onClose: function() {
              that.$router.push('/appletConfig?activeName=qywx')
              that.qywxSetting()
            }
          })
        } else {
          this.$message.error('授权失败，请稍后重试')
        }
      })
      .catch(err=>{
          this.$message.error('授权失败，请稍后重试')
      })
    },
    toWeChat(){
      authUrl({ignore:true})
      .then(res=>{
        console.log(res)
        if (res.code == 200) {
          this.authUrlImg = res.data
          if(!this.authUrlImg) {
            this.$message.error('授权链接获取失败，请尝试刷新页面后重试')
            return
          }
          window.location.href = this.authUrlImg
        } else {
          this.$message.error('授权链接获取失败，请尝试刷新页面后重试')
        }
      })
      .catch(err=>{
        console.log(err)
        this.$message.error('授权链接获取失败，请尝试刷新页面后重试')
      })
    },
  }
};
</script>

<style lang="scss" scoped>
table th{
  background: #F7FAFA;
}
tbody:hover{
  background: #F7FAFA;
}
table tr{
  height: 50px;
}
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.fixed-table {
    table-layout: fixed;
}

.textc {
    text-align: center;
}
.bgf6f9fa {
    background: #F6F9FA;
}
.table-bordered {
    border: 1px solid #dee2e6;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}
body * {
    font-family: "Microsoft YaHei UI";
}
table {
    border-collapse: collapse;
}
* {
    outline: none;
}
*, ::after, ::before {
    box-sizing: border-box;
}
</style>
