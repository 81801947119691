<template>
  <div class="view pa24">
    <div v-if="accountMap && accountMap.state == 1">
      <table class="align-cen-table textc table table-hover table-bordered bgf6f9fa fixed-table text-ellipsis">
        <thead>
          <tr>
            <th>小程序名称</th>
            <th>APPID</th>
            <th>授权时间</th>
          </tr>
        </thead>
        <tbody class="bgfff">
          <tr>
            <td>{{ accountMap.appName }}</td>
            <td>{{ accountMap.appId }}</td>
            <td>{{ accountMap.createTime | getDataTimeSec('yyyy-MM-dd hh:mm:ss') }}</td>
          </tr>
        </tbody>
      </table>
      <el-button type="primary" @click="resetAuthorization">重置授权</el-button>
    </div>
    <el-form v-else :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" class="demo-ruleForm">
      <el-form-item label="App名称" prop="appName">
        <el-col>
          <el-input placeholder="请输入小程序名称" v-model="ruleForm.appName"></el-input>
          <p>
            小程序的名称（请于微信小程序平台中的小程序名称保持一致，进入【设置】--【基本设置】--【小程序名称】查看）
          </p>
        </el-col>
      </el-form-item>
      <el-form-item label="AppId" prop="appId">
        <el-col>
          <el-input type="text" placeholder="请输入小程序AppId" v-model="ruleForm.appId"></el-input>
          <p>
            登录小程序后台（
            <a style="color:#20aee3" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
            ）进入【设置】--【开发设置】--【开发设置AppID,wx开头18位字符】）
          </p>
        </el-col>
      </el-form-item>
      <el-form-item label="AppSecret" prop="appSecret">
        <el-col>
          <el-input type="text" placeholder="请输入小程序AppSecret" v-model="ruleForm.appSecret" maxlength="32"></el-input>
          <p>
            登录小程序后台（
            <a style="color:#20aee3" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
            ）进入【设置】--【开发设置】--【AppSecret（小程序密钥)】--生成密钥（32位字符）
          </p>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :lg="11">
          <el-button type="primary" @click="authorization">授权</el-button>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { componentloginpage } from "@/api/authorization"
import { componentCallBack } from '@/api/wechat'
import { getDataTimeSec } from "@/utils";
import { showLoading, hideLoading } from '@/utils/loading'

export default {
  props: {
    accountMap: {
      default: {}
    }
  },
  data() {
    return {
      ruleForm: {
        appName: "",
        appId: "",
        appSecret: "",
      },
      rules: {
        appName: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "请输入小程序AppId", trigger: "blur" },
        ],
        appSecret: [
          { required: true, message: "请输入小程序AppSecret", trigger: "blur" },
        ],
      },
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    console.log("accountMap", this.accountMap)
    console.log(this.$route.query)
    if (this.$route.query.auth_code) {
      //oem授权回调
      let userAppId = localStorage.getItem('userAppId') || ''
      if (userAppId) {
        showLoading();
        componentCallBack({ authCode: this.$route.query.auth_code, appId: userAppId }).then(res => {
          localStorage.removeItem('userAppId');
          this.getAppInfo();
          hideLoading();
          this.$message.success('用户端授权成功')
        })
        .catch(err => {
          console.log(err);
          hideLoading();
          this.$message.error('用户端授权失败')
        });
      }
    }
  },
  methods: {
    authorization() {
      let data = {
        appName: this.ruleForm.appName,
        appId: this.ruleForm.appId,
        appSecret: this.ruleForm.appSecret,
        accountType: 1 //用户1
      }
      componentloginpage(data).then(res => {
        localStorage.setItem('userAppId', this.ruleForm.appId)
        window.open(res.data);
      })
    },
    getAppInfo() {
      this.$emit('getAppListInfo')
    },
    resetAuthorization() {
      let accountId = ''
      if (this.accountMap.accountType == 1) {
        accountId = this.accountMap.accountId || 0
      }
      if (!accountId) {
        this.$message.error('还未进行授权')
        return
      }
      this.$emit('resetAuthorization', accountId)
    }
  }
};
</script>

<style lang="scss" scoped>
table th {
  background: #F7FAFA;
}

tbody:hover {
  background: #F7FAFA;
}

table tr {
  height: 50px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fixed-table {
  table-layout: fixed;
}

.textc {
  text-align: center;
}

.bgf6f9fa {
  background: #F6F9FA;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

body * {
  font-family: "Microsoft YaHei UI";
}

table {
  border-collapse: collapse;
}

* {
  outline: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}
.el-input {
  width: 500px;
}
</style>
