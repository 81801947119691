import httpUtil from "@/utils/httpUtil";

/**授权接口 */
export const componentloginpage =  params => httpUtil.post("/api/crmPc/component/componentloginpage", params);


/**授权信息 */
export const getCompanyAccountList = async params => httpUtil.post("/api/crmPc/companyAccount/getCompanyAccountList", params);


/**重置授权信息 */
export const unbindCompanyAccount = async params => httpUtil.post("/api/crmPc/companyAccount/unbindCompanyAccount", params);


/**移交企业 */
export const companyTransfer = async params => httpUtil.post("/api/crmPc/companyGoods/companyTransfer", params);


